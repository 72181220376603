var no_institution = document.querySelector('input[name="no_institution[]"]');

if(no_institution){
    no_institution.addEventListener("change", clearField, false);

    //clears institution field when none is ticked
    function clearField(){
        var isChecked = no_institution.checked;
        if(isChecked){
            var institution = document.querySelector('select[name="user_institution"]');

            institution.value = '';
            institution.dispatchEvent(new Event('change'));
        }
    }
}

jQuery(function($) {
    var ajax_object = { "ajax_url":"/wp-admin/admin-ajax.php" };

    var ui = {
        confirm: async (message) => createConfirm(message)
    }
    
    var createConfirm = (message) => {
        return new Promise((complete, failed)=>{
            $('#confirmMessage').html(message)
    
            $('#confirmYes').off('click');
            $('#confirmNo').off('click');
            
            $('#confirmYes').on('click', ()=> { $('.confirm').hide(); complete(true); });
            $('#confirmNo').on('click', ()=> { $('.confirm').hide(); complete(false); });
            
            $('.confirm').show();
        });
    }    

    if($('#approve-user').length) {
        $(document).on('click', '#approve-user', function(e) {
            e.preventDefault();
            var id = $(this).data('id');
            var email = $(this).data('email');

            $.ajax({
                type: 'POST',
                dataType : "html",
                url : ajax_object.ajax_url,
                data: {
                    action: 'approve_user',
                    id: id,
                    email: email,
                },
                success: function (data) {
                    $('#approve-user[data-email="'+email+'"]').parent().html('Approved');
                    $('.message').html('Approved '+email+'.').addClass('success');
                },
                error: function(MLHttpRequest, textStatus, errorThrown){
                    console.log(errorThrown);
                },
            });
        });
    }

    if($('#upgrade-user').length) {
        $(document).on('click', '#upgrade-user', function(e) {
            e.preventDefault();
            var id = $(this).data('id');
            var email = $(this).data('email');
            var institution = $(this).data('institution');

            const confirmation = async () => {
                const confirm = await ui.confirm('There can only be 1 iLead per institution.<br><br>Are you sure you want to make <a href="mailto:'+email+'">'+email+'</a> the new iLead of '+institution+'? Your account will be downgraded.<br><br>');
            
                if(confirm){
                    $.ajax({
                        type: 'POST',
                        dataType : "html",
                        url : ajax_object.ajax_url,
                        data: {
                            action: 'upgrade_user',
                            id: id,
                            email: email,
                        },
                        success: function (data) {
                            $('#upgrade-user[data-email="'+email+'"]').parent().html('iLead');
                            $('.message').html('Upgraded '+email+' to iLead. Reloading page...').addClass('success');

                            setTimeout(function(){
                                window.location.reload();
                             }, 5000);
                        },
                        error: function(MLHttpRequest, textStatus, errorThrown){
                            console.log(errorThrown);
                        },
                    });
                }
            }

            confirmation();
        });
    }

    if($('#delete-user').length) {
        $(document).on('click', '#delete-user', function(e) {
            e.preventDefault();
            var id = $(this).data('id');
            var email = $(this).data('email');

            const confirmation = async () => {
                const confirm = await ui.confirm('Are you sure you want to delete <a href="mailto:'+email+'">'+email+'</a>?');
            
                if(confirm){
                    $.ajax({
                        type: 'POST',
                        dataType : "html",
                        url : ajax_object.ajax_url,
                        data: {
                            action: 'delete_user',
                            id: id,
                            email: email,
                        },
                        success: function (data) {
                            $('#delete-user[data-email="'+email+'"]').parent().parent().fadeOut();
                            $('.message').html('Successfully deleted '+email+'.').addClass('success');
                        },
                        error: function(MLHttpRequest, textStatus, errorThrown){
                            console.log(errorThrown);
                        },
                    });
                }
            }

            confirmation();
        });
    }

    if($('#delete-institution').length) {
        $(document).on('click', '#delete-institution', function(e) {
            e.preventDefault();
            var id = $(this).data('id');
            var institution = $(this).data('institution');

            const confirmation = async () => {
                const confirm = await ui.confirm('Are you sure you want to request deletion of your institution?');
            
                if(confirm){
                    $.ajax({
                        type: 'POST',
                        dataType : "html",
                        url : ajax_object.ajax_url,
                        data: {
                            action: 'delete_institution',
                            id: id,
                            institution: institution
                        },
                        success: function (data) {
                            $('.message').html('Successfully requested deletion of '+institution).addClass('success');
                        },
                        error: function(MLHttpRequest, textStatus, errorThrown){
                            console.log(errorThrown);
                        },
                    });
                }
            }

            confirmation();
        });
    }
});